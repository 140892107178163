import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet'
import {Typography, Box,Grid, Paper} from '@mui/material'
import {getFirestore} from "firebase/firestore"
import { getStorage} from "firebase/storage";
import SiteHeader from '../components/header.js'
import getFirebase from "../components/firebaseConfig.js"
import AltImageDisplay from '../components/visual_entry_components/altImageDisplay'
import theme from '../components/theme.js'
import { ThemeProvider} from '@mui/material/styles';
import { fetchData } from '../components/sharedFunctions.js';
import Closed from "../components/closed.js"
getFirebase()
const db = getFirestore()
const storage = getStorage()

const FeaturedListings = ()=>{
	const [featuredListings, setFeaturedListings] = useState([])
	const [isDisplayingAlt, setIsDisplayingAlt] = useState(null)
	const [dataFetchDone, setDataFetchDone] = useState(false)
	useEffect(async ()=>{
		let listingData = await fetchData(db, storage, {featured:{operation:"==", value:true}})

		let listingsFiltered = []
		listingData.forEach((listing)=>{

			let testCheck = new RegExp('test', 'g')
			if(!testCheck.test(listing.listingName) && listing.data.featured){
				listingsFiltered.push(listing)
			}
		})

		setFeaturedListings(listingsFiltered)
		setDataFetchDone(true)
	},[])

	let featuredListingsJSX = []

	featuredListings.forEach((listing)=>{
		let listingData = listing.data
		featuredListingsJSX.push(
			<Grid item xs={11} md={6} xl={4} >
				<Paper elevation={10} sx={{  padding:"25px", border:"6px solid #E2221D",  borderRadius:"25px",
					[theme.breakpoints.up('md')]: {
						height:"95%",
					},
				}}>
					<Box display="flex" flexDirection="column" sx={{
						[theme.breakpoints.up('md')]: {
							height:"375px"
						},
					}}>
						<Typography variant="h3" style={{fontWeight:"bold", fontSize:25}} align="center">{listing.listingName}</Typography>
						<Box style={{display:"flex", flexDirection:"column",height:"100%"}}>
							<Box height="95%" display='flex' justifyContent="center" alignItems="center" >
								<img src={listing.primaryImg.content} style={{maxWidth:"100%", maxHeight:'225px'}} id={listing.listingName}/>
							</Box>
							<Box style={{display:"flex", alignItems:"flex-end", justifyContent:"center", width:"100%", height:'5%'}}>
								<Typography align="center" variant="caption" style={{textDecoration:"underline", cursor:"pointer"}} 
									onClick={()=>{
										let images=[]
										images.push(listing.primaryImg.content)
										images=images.concat(listing.secondaryImgs.map((img)=>{
											return img.content
										}))
										setIsDisplayingAlt({
											name:listing.listingName,
											images:images
										})
									}}
								>
                                Show all images of item
								</Typography>
							</Box>
						</Box>
					</Box>
					<Box style={{display:"flex", rowGap:"15px", flexDirection:"column"}}>
						<Typography variant="h4" style={{fontWeight:"bold", fontSize:18}}>${listingData.price}</Typography>
						<Typography whiteSpace="pre-line" fontSize={14}>
							{listingData.description}
						</Typography>
					</Box>
				</Paper>
			</Grid>
		)
	})
	return (
		<ThemeProvider theme={theme}>
			<Box style={{position:"relative"}}>
				<Helmet defer={false}>
					<title>Featured Listings</title>
					<meta name="description" 
						content="Featured product listings of Sunny Audio" />
				</Helmet>
				<SiteHeader currentPage="Featured listings"/>
				<Box style={{display:"flex", alignItems:"center",  flexDirection:"column", padding:"20px"}}>
					<Typography variant="h2" align="center">Featured Listings</Typography>
					<Typography margin="40px" fontSize={18}>Remember, 
                you must schedule an appointment by calling us at 
                416-539-9170 <span style={{fontWeight:"bold"}}>
                before coming to our premises to buy an item.
					</span> We are located at 1273 Bloor St. West, Toronto, ON, M6H 1N7. </Typography>
					{isDisplayingAlt ?
						<AltImageDisplay setIsDisplayingAlt = {setIsDisplayingAlt} isDisplayingAlt = {isDisplayingAlt} />
						:
						null
					}
					{featuredListingsJSX.length > 0 ? 
						<Grid container columnSpacing={3} rowSpacing={6} width="100%" sx={{display:"flex-wrap", justifyContent:"center"}}>
							{featuredListingsJSX}
						</Grid>
						: !dataFetchDone ? 
							<Typography variant="h5" align="center" style={{marginTop:"30px"}}>Loading featured listings...</Typography>
							:
							<Typography variant="h5" align="center" style={{marginTop:"30px"}}>None right now, but come back later!</Typography>
					}
				</Box>
			</Box>
		</ThemeProvider>
	)
}
export default FeaturedListings
