/* eslint-disable react/prop-types */
import {Box, Paper, Grid, Typography} from '@mui/material'
import {Close} from '@mui/icons-material'
import React from 'react'
const AltImageDisplay = (props)=>{
	const {isDisplayingAlt, setIsDisplayingAlt} = props
	return (
		<Box style={{position:"fixed", 
			top:0, left:0, width:"100vw", 
			height:"100vh", 
			zIndex:2, 
			display:"flex",
			justifyContent:"center",
			alignItems:"center",
			backgroundColor:"rgba(0,0,0,0.4)"}}>
			<Paper style={{padding:"20px", backgroundColor:"#373736", borderRadius:"25px", position:"relative", overflowY:"auto", height:"90vh", width:"90vw"}}>
				<Box position="fixed" zIndex="4">
					<Close style={{cursor:"pointer"}} onClick={()=>{
						setIsDisplayingAlt(null)
					}}/>
				</Box>
				<Grid container
					style={{
						display:"flex",
						justifyContent:"center",
						position:"relative",
					}}>
					<Grid item xs={12} style={{display:"inline", width:"100%", zIndex:1, padding:"20px"}}>
						<Typography variant="h4" align="center">{isDisplayingAlt.name}</Typography>
					</Grid>
					<Grid container item 
						xs={12}
						alignItems="center"
						columnSpacing={2}
						padding="20px"
						rowSpacing={2}
					>
						{isDisplayingAlt.images.map((img, key)=>{
							return (
								<Grid key={key} item xs={12} style={{display:"flex", justifyContent:"center"}}>
									<img src={img} style={{maxHeight:"80vh", maxWidth:"90%"}}/>
								</Grid>
							)
						})}
					</Grid>
				</Grid>
			</Paper>
		</Box>
	)
}

export default AltImageDisplay